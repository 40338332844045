import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import getCompany from "./api/company";
import { CompanyContext } from "./context/Company";
import Routes from "./routes/Routes";

function App() {
  const [company, setCompany] = useState({});

  useEffect(() => {
    async function getCompanyData() {
      let myCompany = await getCompany() || {};
      setCompany(myCompany)
    }
    getCompanyData()
  }, [])

  return (
    <CompanyContext.Provider value={company}>
      <Routes />
      <ToastContainer />
    </CompanyContext.Provider>
  )
}

export default App;
