import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  NavItem,
} from "reactstrap";
import navRoutes from '../../routes/navRoutes';
import Logo from '../main/Logo';

export default function NavBarMain({scrollToSection, ...props}) {
  const [collapseOpen, toggleCollapse] = React.useState(false);
  useEffect(() => {
    let headroom = new Headroom(document.getElementById("dark-navbar-main"));
    // initialise
    headroom.init();
  });
  let navbarType = "";
  switch (props.type) {
    case "dark":
      navbarType = "bg-default navbar-dark";
      break;
    case "transparent":
      navbarType = "navbar-transparent";
      break;
    case "trans":
      navbarType = "navbar-transparent";
      break;
    case "primary":
      navbarType = "bg-primary navbar-dark";
      break;
    case "white":
      navbarType = "bg-white";
      break;
    default:
      navbarType = "bg-primary navbar-dark";
      break;
  }

  return (
    <>
      <Navbar
        className={"navbar-main headroom " + navbarType}
        expand="lg"
        id="dark-navbar-main"
      >
        <Container>
          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            <Logo size={50} isDark={true} />
          </NavbarBrand>

          <button
            className="navbar-toggler"
            type="button"
            onClick={() => toggleCollapse(!collapseOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <Collapse
            id="navbar_global"
            navbar
            toggler="#navbar_global"
            isOpen={collapseOpen}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/" onClick={() => toggleCollapse(!collapseOpen)}>
                    <img
                      alt="..."
                      src={require("../../assets/logos/logoSm.png")}
                    ></img>
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    onClick={() => toggleCollapse(!collapseOpen)}
                  >
                    <span></span>
                    <span></span>
                  </button>
                </Col>
              </Row>
            </div>
            <Nav
              className="navbar-nav-hover align-items-lg-center ml-lg-auto"
              navbar
            >
              {navRoutes.map((route, key) => {
                const newString = route.route.replace(/\//g, '');
                return (
                  <NavItem key={key}>
                     <NavLink className='pointer' onClick={() => {
                      scrollToSection(`${newString}-page`);
                      toggleCollapse(!collapseOpen);
                     }}>
                      {route.name}
                    </NavLink>
                    {/* <NavLink to={route.route} tag={Link}>
                      {route.name}
                    </NavLink> */}
                  </NavItem>
                )
              })}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}


NavBarMain.defaultProps = {
  type: "dark",
};

NavBarMain.propTypes = {
  type: PropTypes.oneOf(["dark", "transparent", "primary", "white", "trans"]),
};
