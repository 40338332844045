import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../components/main/Footer'
import PhotoGallery from '../../components/main/PhotoGallery'
import NavBarMain from '../../components/navbars/NavBarMain'
import { Get } from '../../api'

export default function View() {
  const { slug } = useParams();
  const [pageData, setPageData] = useState({});


  useEffect(() => {
    async function fetchData() {
      const response = await Get(`/galleries/byslug/${slug}`);
      if (response?.items) {
        setPageData(response);
      }
    }
    fetchData();
  }, [slug]);
  return (
    <>
      <NavBarMain type="dark" />
      {pageData?.items && (
        <>
          <PhotoGallery
            title={pageData?.name}
            details={pageData?.details}
            images={pageData?.items}
          />
        </>
      )}
      
      <Footer />
    </>
  )
}
