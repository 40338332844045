import React, { useEffect, useRef } from 'react'
import '../App.css';
import NavBarMain from '../components/navbars/NavBarMain';
import FullScreenHeader from '../components/main/FullScreenHeader';
import Feature4 from '../components/main/Feature4';
import Footer from '../components/main/Footer';
import Space from './Space';
import Tour from './Tour';
import GallerySelection from '../components/LOB/Gallery';
import Contact from './Contact';

export default function Landing() {
  const topOfPage = useRef(null);
  const spacePage = useRef(null);
  const tourPage = useRef(null);
  const contactPage = useRef(null);


  useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  const scrollToSection = (sectionId) => {
    let ref;
    switch (sectionId) {
      case '-page':
        ref = topOfPage.current;
        break;
      case 'space-page':
        ref = spacePage.current;
        break;
      case 'tour-page':
        ref = tourPage.current;
        break;
      case 'contact-page':
        ref = contactPage.current;
        break;
        case 'rental-page':
        ref = contactPage.current;
        break;
      default:
        break;
    }
    if (ref) {
      const currentScroll = window.scrollY;
      console.log('cur', currentScroll);
      let fixedMenuHeight = document.getElementById('dark-navbar-main').offsetHeight;
      if (currentScroll === 0) {
        fixedMenuHeight = 330;
      } else {
        fixedMenuHeight = fixedMenuHeight - 5;
      }
      const offsetPosition = ref.offsetTop - fixedMenuHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    } else {
      console.error(`Section with id ${sectionId} not found`);
    }
  };
  return (
    <>
      <div ref={topOfPage} />
      <NavBarMain scrollToSection={scrollToSection} type={'trans'} />
      <FullScreenHeader
        image={'https://mirrorcorp.s3.us-west-2.amazonaws.com/images/banners/LandingBanner.jpeg'}
        filter
      />
      <Feature4
        title={'We do Events'}
        image={'https://mirrorcorp.s3.us-west-2.amazonaws.com/images/Dreamforce/19Medium.png'}
        imageTitle={'715 HARRISON ST'}
        imageDetails={'Our venue\'s 15,000 square foot floor plan is a versatile and flexible space with multiple rooms, two dance floors, a large stage, and three full bars. Whether you\'re hosting a corporate event, private party, or musical performance, our state-of-the-art sound and lighting systems and mobile bars provide everything you need for a successful event.'}
      />

      

      <div ref={tourPage} />
      <Tour />

      {/* Start of pages  */}
      <div ref={spacePage} />
      <Space />

      <div ref={contactPage} />
      <Contact />
      {/* End of pages */}
      <Footer />
    </>
  )
}
