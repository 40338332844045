import React, { useEffect } from 'react'
import NavBarMain from '../components/navbars/NavBarMain';
import Footer from '../components/main/Footer';
import GallerySelection from '../components/LOB/Gallery';
import VideoHeader from '../components/LOB/VideoHeader';
import Feature7 from '../components/features/Feature7';

function Tour() {
  useEffect(() => {
    document.body.classList.add("profile-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  }, []);
  
  return (
    <>
      {/* <NavBarMain type={'trans'} /> */}
      <VideoHeader title="Tour 715 Harrison" />
      <Feature7
        title='Complete Packages'
        details='We can provide everything you need for a successful event, including fitness equipment rentals, mobile bars, and catering services.'
      />
      <div className="wrapper">
      <GallerySelection />
      </div>
      {/* <Footer /> */}
    </>

  )
}

export default Tour