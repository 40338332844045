import { Col, Container, Row } from "reactstrap";
import Logo from "./Logo";
import { useEffect, useState } from "react";

  const FullScreenHeader = ({ image, filter, noLogo, title }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
      <>
        <div className={`page-header ${filter ? "header-filter" : ""}`} style={{
          minHeight: `${100 - scrollPosition}vh`
          // minHeight: `${scrollPosition < 5 ? '100vh': (100 - scrollPosition)}vh`
        }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage: `url(${image})`,
            }}
          ></div>
          <Container>
            <Row>
              <Col className="mx-auto text-center" lg="10">
                {noLogo ? null : <Logo size={isMobile ? 150 : 300} isDark={true} />}
                {title ? <h1 className="display-1 text-white">{title}</h1> : null}
                {/* <h2 className="display-2 text-white">{props.title}</h2> */}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  };

  export default FullScreenHeader;