import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

// Core Components

function HeaderBlogPosts({image, details}) {
  return (
    <>
      <div className="page-header page-header-small header-filter">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + image + ")"
          }}
        ></div>
        <div className="content-center">
          <Row>
            <Col className="mx-auto text-center" md="6">
              <h1 className="title text-white">
                {details}
              </h1>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default HeaderBlogPosts;
