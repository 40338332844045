/*eslint-disable*/
import React, { useContext } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { NavLink, Container } from "reactstrap";
import { CompanyContext } from "../../context/Company";
import navRoutes from "../../routes/navRoutes";

// Core Components

function Footer() {
  const company = useContext(CompanyContext)
  return (
    <>
      <footer className="footer footer-simple">
        <Container>
          <nav>
            <ul>
              {navRoutes.map((route, key) => (
                <li key={key}>
                  <NavLink
                    to={route.route}
                    tag={Link}
                  >
                    {route.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
          <div className="copyright text-right">
            © {new Date().getFullYear()}, {company.name}
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
