import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Footer from '../components/main/Footer';
import NavBarMain from '../components/navbars/NavBarMain';

function RentalReq() {

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
      <NavBarMain />
      <div className="main margin-bottom-50">
        <Container fluid>
          <Row className="pt-5">
            <Col id='contactForm' className="ml-auto mr-auto text-center mt-5" md="8">
              <h2 className="title margin-top-20">
                715 Harrison Events | Inquiry Form
              </h2>
              <h4 className="desc">
                Complete the form below then click submit.  Our event coordinator will contact you shortly. <br />
                We look forward to being a part of building your event!
              </h4>
            </Col>
          </Row>
          <Row>
            <Col className="mx-auto" md="4">
              <Card
                className="bg-secondary p-3 mt-5"
              >
                <CardBody>
                  {/* <div data-pv-venue-contact-form-id="41c82d3072"></div> */}

                  <iframe height="1000" width="100%" frameBorder="0" title='Booking' id="tripleseat_embed_iframe" src="https://api.tripleseat.com/v1/leads/iframe_embed?lead_form_id=34059&public_key=c210139641be3a87635db33ce3da5af659084142"></iframe>

                  <div>
                  </div>



                  {/* <Form onSubmit={handleSubmit}>
                    <Row>

                      <Row>
                        {inputs.map((input, key) => (
                          <ContactFormLayout key={key} {...input} value={values[input.name]} onChange={onChange} />
                        ))}
                      </Row>

                    </Row>
                    <Row className="justify-content-end">
                      <Col className="text-right" md="6">
                        <Button color="primary" type="submit">
                          Send Request
                        </Button>
                      </Col>
                    </Row>
                  </Form> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default RentalReq