import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";



const PhotoGallery = ({title, details, images}) => {
  const [index, setIndex] = useState(-1);
  images = images?.map((item, key) => {
    const img = new Image();
      img.src = item;
      return {
        src: img.src,
        original: img.src,
        width: img.naturalWidth > img.naturalHeight ? 320 : 174,
        height: img.naturalWidth > img.naturalHeight ? 174 : 212,
      }

  });
  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);


  return (
    <>
      <Container>
        <Row>
          <Col className="mx-auto margin-bottom-50 margin-top-70" md="12">
            <h3 style={{textTransform: 'uppercase'}} className="display-3 margin-top-30">{title}</h3>
            <p className="lead">
              {details === 'undefined' ? '' : details}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className='margin-bottom-30'>
            <Gallery
              images={images}
              onClick={handleClick}
              enableImageSelection={false}
            />
            {!!currentImage && (
              /* @ts-ignore */
              <Lightbox
                mainSrc={currentImage.original}
                imageTitle={currentImage.caption}
                mainSrcThumbnail={currentImage.src}
                nextSrc={nextImage.original}
                nextSrcThumbnail={nextImage.src}
                prevSrc={prevImage.original}
                prevSrcThumbnail={prevImage.src}
                onCloseRequest={handleClose}
                onMovePrevRequest={handleMovePrev}
                onMoveNextRequest={handleMoveNext}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PhotoGallery



// import React, { useState } from 'react';

// import { Gallery } from "react-grid-gallery";
// import Lightbox from "react-image-lightbox";
// import "react-image-lightbox/style.css";
// import LightGallery from 'lightgallery/react';
// import 'lightgallery/css/lightgallery.css';
// import 'lightgallery/css/lg-zoom.css';
// import 'lightgallery/css/lg-thumbnail.css';

// // If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

// // import plugins if you need
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';
// import { Col, Container, Row } from 'react-bootstrap';



// const PhotoGallery = ({ title, details, images }) => {
//   return (
//     <>
//       <Container>
//         <Row>
//           <Col className="mx-auto margin-bottom-50 margin-top-70" md="12">
//             <h3 style={{ textTransform: 'uppercase' }} className="display-3 margin-top-30">{title}</h3>
//             <p className="lead">
//               {details}
//             </p>
//           </Col>
//         </Row>
//         <Row>
//           <Col className='margin-bottom-30'>
//             {images?.length > 0 ? (
//               <>
//                 <Row className='gallery-only'>
//                   <LightGallery
//                     speed={500}
//                     plugins={[lgThumbnail, lgZoom]}
//                     addClass='reset grid grid-cols-1 md:grid-cols-4'
//                     galleryClassName='reset'
//                     style={{ all: 'unset' }}
//                     selector={'.image-card-gallery'}
//                   >
//                     <Row>
//                     {images?.map((item, key) => (
//                       <Col
//                         md='3'
//                         data-src={item}
//                         data-href={item}
//                         className="relative mb-6 rounded-lg overflow-hidden border-2 border-#38404e hover:border-blue-500 transition-all mb-4 image-card-gallery"
//                         href={item}
//                         style={{ border: '2px solid #38404e', marginBottom: '1rem' }}
//                         key={key}
//                       >
//                         <img
//                           alt="Club X"
//                           src={item}
//                           className="w-full object-cover object-top rounded-lg"
                          
//                           // style={{ height: '575px' }}
//                         />
//                       </Col>
//                     ))}
//                     </Row>
//                   </LightGallery>
//                 </Row>
//               </>
//             ) : null}
//           </Col>
//         </Row>
//       </Container>
//     </>
//   )
// }

// export default PhotoGallery