const navRoutes = [
  {
    route : '/',
    name : 'Home'
  },
  {
    route : '/tour',
    name : 'Tour Venue'
  },
  {
    route : '/space',
    name : 'Our Space'
  },
  
  {
    route : '/contact',
    name : 'Contact'
  },
  {
    route : '/rental',
    name : 'Rental Request'
  },
]

export default navRoutes;