import React from "react";

// reactstrap components
import { Card, CardImg, Container, Row, Col } from "reactstrap";

// Core Components

function Feature4({ title, intro, data, image, imageTitle, imageDetails }) {
  return (
    <>
      <div className="section features-4">
        <Container>
          <Row>
            <Col className="text-center mx-auto margin-bottom-50" md="12">
              <h3 className="display-3">{title}</h3>
              <p className="lead">
                {intro ? intro : (
                  <>
                    <b>Our passion is being a part of the San Francisco cultural scene.</b><br />
                    <br />
                    <font style={{fontSize: '1.20rem'}}>Located in the heart of San Francisco’s SOMA district, only a few blocks away from Moscone Convention Center. This versatile event space has 15,000 square feet, multiple rooms, two dance floors, three full bars (additional mobile bars are available) and is fully equipped with state-of the-art sound and lighting systems. We staff our own awesome bartenders. We can host events of all ages at our venue, but you must be 21+ with vaild ID to drink.</font>
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row className="align-items-center">
          <Col className="mr-auto text-left mt-4" lg="12">
            <Card className="bg-default shadow border-0">
              <div className="banner-container">
                <div style={{ backgroundImage: `url('${image}')` }} className="banner-overlay"></div>
                <div style={{ backgroundImage: `url('${image}')` }} className="banner-image"></div>
              </div>

              <blockquote className="card-blockquote">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="svg-bg"
                  preserveAspectRatio="none"
                  viewBox="0 0 583 95"
                >
                  <polygon
                    className="fill-default"
                    points="0,52 583,95 0,95"
                  ></polygon>
                  <polygon
                    className="fill-default"
                    opacity=".2"
                    points="0,42 583,95 683,0 0,95"
                  ></polygon>
                </svg>
                <h4 className="display-4 text-white">{imageTitle}</h4>
                <p className="lead text-italic text-white">
                  {imageDetails}
                </p>
              </blockquote>
            </Card>
          </Col>
        </Row>
        </Container>
        
      </div>
    </>
  );
}

export default Feature4;
