// import { CompanyContext } from 'contexts/CompanyContaxt'
import React, { useContext } from 'react'
import { Image } from 'react-bootstrap';
import { CompanyContext } from '../../context/Company';

export default function Logo(props) {
  const company = useContext(CompanyContext);
  return (
    <div className='crop'>
        <Image src={props.isDark ? company.logoDark : company.logoLight} style={{height: props.size, width: 'auto'}} fluid={true} alt="Company Logo" />
    </div>
    
  )
}
