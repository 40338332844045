import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { MdOutlinePlayCircleOutline } from "react-icons/md";



export default function VideoHeader({ title, description, video }) {
  const videoEl = useRef(null);
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleVideoLoad = () => {
    setIsLoading(false); // Hide the placeholder once the video is ready
  };

  const handlePlayButtonClick = () => {
    if (videoEl.current) {
      // Check if the browser supports the Fullscreen API
      if (videoEl.current.requestFullscreen) {
        videoEl.current.requestFullscreen();
      } else if (videoEl.current.webkitRequestFullscreen) { // Safari
        videoEl.current.webkitRequestFullscreen();
      } else if (videoEl.current.msRequestFullscreen) { // IE11
        videoEl.current.msRequestFullscreen();
      }

      // Unmute the video
      videoEl.current.muted = false;

      // Play the video
      videoEl.current.play().catch(error => console.error("Error attempting to play video:", error));
    }
  };

  const VideoBackground = () => {

    const attemptPlay = () => {
      videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
          console.error("Error attempting to play", error);
        });
    };
    useEffect(() => {
      attemptPlay();
    }, []);

    return (
      <>
      {isLoading && (
          <div
          className="page-header-image"
          style={{
            backgroundImage: `url('https://mirrorcorp.s3.us-west-2.amazonaws.com/images/adPhotos/23Medium.png')`,
            position: 'absolute',
            width: '100%',
            top: 0,
            height: '100vh',
            objectFit: 'cover',
          }}
        />
        )}
        <video
          autoPlay
          muted
          loop
          playsInline
          ref={videoEl}
          onLoadedData={handleVideoLoad}
          style={{
            position: 'absolute',
            width: '100%',
            top: 0,
            height: '100vh',
            objectFit: 'cover',
            zIndex: -1,
            left: 0
          }}
        >
          <source src="https://715harrison.s3.us-west-1.amazonaws.com/videos/CityNightsWalkthrough.mp4" type="video/mp4" />
        </video>
      </>
    )
  }
  const EventsSlider = () => {
    return (
      <div className="content-center">
        <Row>
          <Col
            className="ml-auto mr-auto"
            md="4"
          >
            <h1 style={{textTransform: 'uppercase', fontWeight: 800, marginTop: isMobile ? -200 : 0 }} className="title text-white">{title}</h1>
            <p className="description">
              {description}
            </p>
            
            <MdOutlinePlayCircleOutline
              size={50}
              color='white'
              onClick={handlePlayButtonClick}
              style={{
                cursor: 'pointer',
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <div className="header header-3">
      <div className="page-header header-filter custom-page-header">
        <VideoBackground />
        <EventsSlider />
      </div>
    </div>
  )
}