import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Feature7({title, details}) {
  return (
    <>
      <div className="section features-7 bg-secondary margin-bottom-30">
        <Container>
          <Row>
            <Col className="text-center mx-auto" md="8">
              <h3 className="display-3">{title}</h3>
              <p className="lead">
              {details}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature7;
