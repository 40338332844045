import {Get} from './'

async function getCompany () {
    let address = window.location.hostname;

    console.log('THE COMPANY')
    console.log(address)

    return await Get('/companies/getByAddress/' + address).then(res => {
        console.log(res)
        return res || {}
    });
}

export default getCompany;