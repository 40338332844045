import React, { useContext, useEffect, useState } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { CompanyContext } from "../../context/Company";
import { Get } from "../../api/Server";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import { Gallery } from "react-grid-gallery";
import "react-image-lightbox/style.css";

const RenderGallery = ({ item }) => {
  let images = item?.items || [];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [index, setIndex] = useState(-1);

  images = images?.map((item, key) => {
    const img = new Image();
    img.src = item;
    return {
      src: img.src,
      original: img.src,
      width: 270,
      height: 150
    }
  });

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Col style={{ marginBottom: 50 }} md={12} className="gallery-list">
      <div className="" style={{ width: windowWidth > 786 ? '75%' : '93%', margin: 25 }}>
        <h2>{item?.name}</h2>
        <p style={{ marginBottom: 35 }}>{item?.details}</p>
      </div>

      <Card
        className="card-blog"
        data-animation="zooming"
        style={{ height: 300, width: '100%', margin: 'auto' }}
      >
        <Gallery
          images={images}
          onClick={handleClick}
          enableImageSelection={true}
          rowHeight={150}
          maxRows={2}
        />
        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.original}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}

        <Link to={`#`}>
          <CardBody>
            <div className="content-bottom" style={{ top: '100%', marginTop: -85 }}>
              <CardTitle tag="h5">{item?.name}</CardTitle>
            </div>
          </CardBody>
        </Link>
      </Card>
    </Col>
  )
}

function GallerySelection() {
  const company = useContext(CompanyContext);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    async function getData() {
      if (company?._id) {
        let response = await Get(`/galleries/byCompany/byOrg/${company._id}/715_harrison`);
        // revrse the order of the galleries
        response.galleries = response.galleries.reverse();
        setPageData(response);
      }
    }
    getData();
  }, [company]);



  return (
    <>
      <section className="blogs-2">
        <Container>
          <Row>
            {pageData?.galleries && Array.isArray(pageData?.galleries) ? (
              <>
                {
                  pageData?.galleries.map((item, key) => (
                    <RenderGallery key={key} item={item} />
                  ))
                }
              </>
            ) : null}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default GallerySelection;
