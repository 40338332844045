// import { CompanyContext } from "contexts/CompanyContaxt";
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function ContactHeader(props) {
  // const company = useContext(CompanyContext)

  const getPhone = (phone) => {
    var cleaned = ('' + phone).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }


  return (
    <>
      <div className="contactus-3">
        <section className="section section-lg section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <div>

            <Row>
              <Col className="text-center my-5" md="12">
                <h1 style={{ color: 'white' }} className="display-1 mt-3">Have a question?</h1>
                <h3 style={{ color: 'white' }} className="lead">
                  We'd like to talk more about your needs.<br /> Email <b>us</b> today!
                </h3>
              </Col>
            </Row>
          </div>
        </section>
        <Container>
          <Row className="margin-top-30">
            <Col lg="4" md="6" xs="6">
              <div className="info info-hover">
                <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                  <i className="ni ni-square-pin"></i>
                </div>
                <h4 className="info-title">Address</h4>
                <p className="description px-0">
                  {props.address} <br />
                  {props.city} {props.state} {props.zip}
                </p>
              </div>
            </Col>
            <Col lg="4" md="6" xs="6">
              <div className="info info-hover">
                <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                  <i className="ni ni-email-83"></i>
                </div>
                <h4 className="info-title">Email</h4>
                <p className="description px-0">{props.email}</p>
              </div>
            </Col>
            <Col lg="4" md="6" xs="6">
              <div className="info info-hover">
                <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                  <i className="ni ni-mobile-button"></i>
                </div>
                <h4 className="info-title">Phone</h4>
                <p className="description px-0">{getPhone(props.phone)}</p>
              </div>
            </Col>
            {/* <Col lg="3" md="6" xs="6">
              <div className="info info-hover">
                <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                  <i className="ni ni-circle-08"></i>
                </div>
                <h4 className="info-title">Contact</h4>
                <p className="description px-0">{props.contact}</p>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContactHeader;
