import React, { useContext, useEffect, useState } from 'react';
import Footer from '../components/main/Footer';
import NavBarMain from '../components/navbars/NavBarMain';
import ContactHeader from '../components/main/ContactHeader';
import { toast } from 'react-toastify';
import { Badge, Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import FormInput from '../components/main/FormInput';
import { Post } from '../api';
import { CompanyContext } from '../context/Company';

function Contact(props) {
  const company = useContext(CompanyContext);
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });

  const resetForm = () => {
    setValues({
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      subject: '',
      message: ''
    });
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const inputs = [{
    id: 1,
    name: 'first_name',
    type: 'text',
    label: 'First Name',
    placeholder: 'First Name',
    size: '6',
    required: true,
    errorMessage: 'Please input your first name',
    icon: 'ni-circle-08',
    class: 'mb-3'
  },

  {
    id: 2,
    name: 'last_name',
    type: 'text',
    label: 'Last Name',
    placeholder: 'Last Name',
    size: '6',
    icon: 'ni-circle-08',
    class: 'mb-3'
  },
  {
    id: 3,
    name: 'phone',
    type: 'phone',
    label: 'Phone',
    placeholder: 'Phone',
    size: '6',
    errorMessage: 'Please input your phone number so we can get incontact with you.',
    required: true,
    icon: 'fa-phone',
    class: 'mb-3'
  },
  {
    id: 4,
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Email',
    size: '6',
    errorMessage: 'Please input your email',
    required: true,
    icon: 'ni-email-83',
    class: 'mb-3'

  },
  {
    id: 5,
    name: 'subject',
    type: 'text',
    label: 'Subject',
    placeholder: 'Subject',
    size: '12',
    icon: 'ni-email-83',
    class: 'mb-3'
  },
  {
    id: 6,
    name: 'message',
    type: 'textarea',
    label: 'Message',
    placeholder: 'Tell us about your project',
    size: '12',
    errorMessage: 'Please tell us about your project so we can better understand',
    required: true,
    icon: null,
    rows: 6,
    class: 'mb-3'
  }]
  useEffect(() => {
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  }, []);

  const notify = (data, type) => {
    toast(data, {
      type: type || 'default',
      theme: 'dark',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = values;
    Post('/contacts', {
      first_name: data.name,
      phone: data.phone,
      email: data.email,
      subject: data.subject,
      message: data.message,
      company: company._id
    }).then(() => {
      resetForm();
      notify('Your message has been sent!', 'success');
    })
  }

  return (
    <>
      {/* <NavBarMain type={'trans'} /> */}
      <ContactHeader
        address={'715 Harrison St'}
        city={'San Francisco'}
        state={'CA'}
        zip={'94107'}
        email={'Events@715Harrison.com'}
        phone={'4155467938'}
        contact={'Eddie Sumano'}
      />
      <div {...props} className="main margin-bottom-50">
        <Container fluid>
          <Row className="mb-4 pt-5">
            <Col id='contactForm' className="ml-auto mr-auto text-center mt-5" md="8">
              <Badge color="info">Inquiry Form</Badge>
              <h1 className="title">
              715 Harrison Events
              </h1>
              <h5 className="desc">
              Complete the form below then click submit.  Our event coordinator will contact you shortly. <br />
              We look forward to being a part of building your event!
              </h5>
            </Col>
          </Row>
          <Row>
            <Col className="mx-auto" md="6">
              <Card
                className="bg-secondary p-3"
              >
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>

                    <iframe height="1000" width="100%" frameBorder="0" title='Booking' id="tripleseat_embed_iframe" src="https://api.tripleseat.com/v1/leads/iframe_embed?lead_form_id=34059&public_key=c210139641be3a87635db33ce3da5af659084142"></iframe>

                      {/* {inputs.map((input) => (
                        <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange} />
                      ))} */}

                    </Row>
                    {/* <Row className="justify-content-end">
                      <Col className="text-right" md="6">
                        <Button color="primary" type="submit">
                          Send Message
                        </Button>
                      </Col>
                    </Row> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Footer /> */}
    </>
  )
}

export default Contact