import React from 'react'
import { useRoutes } from 'react-router-dom';
import PublicRoute from './PublicRoute';

import Landing from '../views/Landing';
import Space from '../views/Space';
import Galley from '../views/gallery/';
import RentalReq from '../views/RentalReq';
import Contact from '../views/Contact';
import Tour from '../views/Tour';
import View from '../views/gallery/View';

function AllRoutes() {
  return useRoutes([
    {
      // public routes
      path: '/',
      element: <PublicRoute />,
      children: [
        {
          path: '',
          element: <Landing />,
        },
        {
          path : 'space',
          element : <Space />
        },
        {
          path : 'tour-page',
          element : <Tour />
        },
        {
          path : 'gallery',
          element : <Galley />
        },
        {
          path : 'photos/:slug',
          element : <View />
        },
        {
          path : 'rental',
          element : <RentalReq />
        },
        {
          path : 'contact',
          element : <Contact />
        },
      ],
    }
  ]);
}

export { AllRoutes }