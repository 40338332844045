import React, { useEffect, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const loading = () => <div className="">Loading........</div>;
const PublicRoute = (props) => {

    useEffect(() => {
      
    }, []);

    return (
        <Suspense fallback={loading()}>
            <Outlet />
        </Suspense>
    );
};
export default PublicRoute;