import jQuery from 'jquery';
let APIRoute = '/api';
// let APIRoute = 'https://32d374cc3c57.ngrok.io/api';
const companyId = '61e2056c65fba2004e536f70';

function Get(url) {
  return jQuery.getJSON(`${APIRoute}${url}`);
}

function Post(url, data) {
  if (typeof data === typeof {}) {
    data = JSON.stringify(data);
  }
  return fetch(`${APIRoute}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((error) => error);
}

function PostWithMedia(route, params) {
  const baseUrl = route;

  const formData = new FormData();
  for (const k in params) {
    if (k === "files" && typeof params[k] !== 'undefined') {
      for (let foo = 0; foo < params[k].length; foo++) {
        formData.append("files", params[k][foo]);
      }
    }
    formData.append(k, params[k]);
  }
  return jQuery.ajax({
    url: `${APIRoute}${baseUrl}`,
    type: 'POST',
    data: formData,
    cache: false,
    dataType: 'json',
    processData: false, // Don't process the files
    contentType: false, // Set content type to false as jQuery will tell the server its a query string request
  })
}

function Put(url, data) {
  if (typeof data === typeof {}) {
    data = JSON.stringify(data);
  }
  return jQuery.ajax({
    url: `${APIRoute}${url}`,
    type: 'PUT',
    contentType: "application/json; charset=utf-8",
    dataType: "json",
    data: data
  });
}

function Delete(url) {
  return fetch(`${APIRoute}${url}`, {
    method: 'Delete',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .catch((error) => error);
}

export { Get, Post, PostWithMedia, Put, Delete, companyId };
