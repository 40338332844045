import React from 'react'
import { Card, CardImg, CardImgOverlay, CardTitle, Col, Container, Row } from 'reactstrap'
import Footer from '../../components/main/Footer'
import NavBarMain from '../../components/navbars/NavBarMain';
import { Link } from 'react-router-dom';

function Index() {
  return (
    <>
      <NavBarMain />
      <Container>
        <Row className="justify-content-center">
          <Col className="mx-auto margin-bottom-50 margin-top-70" md="12">
            <h3 className="display-3 margin-top-30">{'Our Gallery'}</h3>
          </Col>

          <Col md="6" className="text-center">
            <Card className="text-white bg-default">
              <Link to={'/photos/fitness'}>
                <CardImg alt="..." src={'https://mirrorcorp.s3.us-west-2.amazonaws.com/images/rental/fitness/2.png'}></CardImg>
                <CardImgOverlay className="d-flex align-items-center">
                  <div className="quick-facts-container">
                    <div className="card-description all-quick">
                      <CardTitle className="h2 text-white mb-2">{"Fitness"}</CardTitle>
                    </div>
                  </div>
                </CardImgOverlay>
              </Link>
            </Card>
          </Col>

          <Col md="6" className="text-center">
            <Card className="text-white bg-default">
              <Link to={'/photos/expositions'}>
                <CardImg alt="..." src={'https://mirrorcorp.s3.us-west-2.amazonaws.com/images/rental/expositions/2.png'}></CardImg>
                <CardImgOverlay className="d-flex align-items-center">
                  <div className="quick-facts-container">

                    <div className="card-description all-quick">
                      <CardTitle className="h2 text-white mb-2">{"Expositions"}</CardTitle>
                    </div>
                  </div>
                </CardImgOverlay>
              </Link>
            </Card>
          </Col>

          <Col md="8" className="text-center">
            <Card style={{height:300, overflow: 'hidden'}} className="text-white bg-default">
              <Link to={'/photos/corporate'}>
                <CardImg alt="..." className='img-height' src={'https://mirrorcorp.s3.us-west-2.amazonaws.com/images/Dreamforce/5.png'}></CardImg>
                <CardImgOverlay className="d-flex align-items-center">
                  <div className="quick-facts-container">
                    <div className="card-description all-quick">
                      <CardTitle className="h2 text-white mb-2">{"Corporate"}</CardTitle>
                    </div>
                  </div>
                </CardImgOverlay>
              </Link>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Index