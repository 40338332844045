import React from "react";

// reactstrap components
import { Card, CardImg, Row, Col } from "reactstrap";

// Core Components

function SpeceSheet({ title, intro, data, image }) {
  return (
    <>
      <div style={{margin: '0 50px'}} className="section features-4">
        <>
          <Row>
            <Col className="text-center mx-auto" md="12">
              <p className="lead">
                {intro ? intro : null}
              </p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="mr-auto text-left mt-4" lg="4">
              <Card className="bg-default shadow border-0">
                <CardImg
                  alt="..."
                  src={image}
                ></CardImg>
              </Card>
              <div className="text-center">
                
                ( Installed and maintained by <a href="https://litebriteproductions.com" target={'_blank'} rel="noreferrer">'Lite Brite Productions'</a>  )
              </div>
            </Col>
            <Col className="p-sm-0" lg="7">
              <Row>

                {data.map((d, key) => (
                  <Col key={key} md="6" className="cell-text-center margin-top-20">
                    <div className={``}>
                      <div style={{backgroundColor : 'gray', marginBottom: 20}} className={`icon-shape shadow rounded-circle text-primary`}>
                        {d.icon ? d.icon : <i className="ni ni-satisfied text-white"></i>}
                      </div>
                      <h5 className="info-title">{d.title}</h5>
                      <p className="description opacity-8">
                        <ul>
                          {d.list.map((l, key) => (
                            <li key={key} style={{margin: '5px 0'}}><img style={{marginRight: 10}} alt={'VenueImage'} src="https://mirrorcorp.s3.us-west-2.amazonaws.com/logos/logoSm.png" height={18} /> {l}</li>
                          ))}
                        </ul>
                      </p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </>
      </div>
    </>
  );
}

export default SpeceSheet;
